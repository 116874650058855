import { Link } from "react-router-dom";

export default function EmailSentConfirmation({ email }: { email: string }) {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 text-secondary-10">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white p-8 rounded-xl flex flex-col gap-5 shadow">
            <h2 className="text-2xl font-semibold ">
              Password Reset Email Sent!
            </h2>

            <div className="space-y-6">
              <p>
                We’ve sent a password reset link to{" "}
                <span className="font-semibold text-primary-50">{email}</span>.
                Check your inbox and follow the instructions to reset your
                password.{" "}
              </p>
              <p>
                {" "}
                If you don’t see the email, please check your spam or junk
                folder. Still need help? Reach out to us at{" "}
                <a
                  href={"mailto:support@creovia.io"}
                  className="font-medium hover:text-primary-50"
                >
                  support@creovia.io
                </a>{" "}
              </p>
              <button className="w-full text-white bg-primary-50 py-3 px-4 rounded-lg hover:bg-primary-40 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed">
                <Link to="/">Return to log in</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
