import { ChangeEvent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { User } from "@types";
import { UpdatePassword } from "./components";
import { Form } from "@modules/common/components";
import { useLogin } from "@modules/auth/hooks";
import { useGetQueryData } from "@modules/common/hooks";
import Logo from "@icons/logos/logo";
import { Eye, EyeClosed } from "lucide-react";

export default function Login() {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });

  const user = useGetQueryData<User | undefined>(["user"]);
  const { mutate, isPending, error } = useLogin();

  const [hasAutofill, setHasAutofill] = useState(false);

  useEffect(() => {
    // Create an interval that checks for autofill every 100ms
    const intervalId = setInterval(() => {
      const emailInput = document.getElementById('email');
      const passwordInput = document.getElementById('password');

      const shouldSetHasAutofill = emailInput?.matches(':-webkit-autofill') ||
        passwordInput?.matches(':-webkit-autofill');

      if (shouldSetHasAutofill) {
        setHasAutofill(true);
        // Clear the interval once autofill is detected
        clearInterval(intervalId);
      }
    }, 100);

    // Cleanup the interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  }

  function handleSubmit() {
    mutate({ ...formState, username: formState.email });
  }

  function handleTogglePassword(e: React.MouseEvent) {
    e.preventDefault();
    setIsShowPassword(!isShowPassword);
  }

  if (user && user.force_password_change) return <UpdatePassword />;

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          {/* Card */}
          <div className="bg-white dark:bg-slate-800 p-8 rounded-xl flex flex-col gap-5 shadow-md">
            <div className="flex justify-center">
              <Logo darkFill="#fff" lightFill="#000" />
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">
                Log in
              </h2>
              {error ? (
                <h2 className="mt-2 text-red-600 dark:text-red-400">
                  {error.message}
                </h2>
              ) : null}
            </div>

            <style>
              {`
          @keyframes onAutoFillStart {
            from {/**/}
            to {/**/}
          }

          input:-webkit-autofill {
            animation-name: onAutoFillStart;
            animation-fill-mode: both;
          }
        `}
            </style>
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900 dark:text-gray-100"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={formState.email}
                    onChange={handleChange}
                    required
                    placeholder="Enter your email"
                    className="w-full bg-white dark:bg-slate-700 text-gray-900 dark:text-white px-4 py-3 rounded-lg border border-gray-300 dark:border-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm/6 font-medium text-gray-900 dark:text-gray-100"
                >
                  Password
                </label>
                <div className="relative mt-2">
                  <input
                    id="password"
                    name="password"
                    type={isShowPassword ? "text" : "password"}
                    autoComplete="current-password"
                    value={formState.password}
                    onChange={handleChange}
                    required
                    placeholder="Enter your password"
                    className="w-full bg-white dark:bg-slate-700 text-gray-900 dark:text-white px-4 py-3 rounded-lg border border-gray-300 dark:border-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePassword}
                    className="absolute top-1/2 right-4 -translate-y-1/2 w-5 h-5 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 cursor-pointer select-none"
                    aria-label={
                      isShowPassword ? "Hide password" : "Show password"
                    }
                  >
                    {isShowPassword ? (
                      <EyeClosed className="w-5 h-5" />
                    ) : (
                      <Eye className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>
              <div className="py-b">
                <Link
                  to="/reset"
                  className="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300"
                >
                  Forgot your password?
                </Link>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={(!hasAutofill && (formState.email === "" || formState.password === ""))}
                  className="w-full text-white bg-primary-50 py-3 px-4 rounded-lg hover:bg-primary-40 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isPending ? "Logging in..." : "Log in"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
