import { useRequestPasswordReset } from "@modules/auth/hooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import { EmailSentConfirmation } from "./components";
import Logo from "@icons/logos/logo";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { mutate: forgotPassword, isSuccess } = useRequestPasswordReset();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    forgotPassword(email);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  if (isSuccess) return <EmailSentConfirmation email={email} />;

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          {/* Card */}
          <div className="bg-white dark:bg-slate-800 p-8 rounded-xl flex flex-col gap-5 shadow-md">
            <div className="flex justify-center">
              <Logo darkFill="#fff" lightFill="#000" />
            </div>

            <div>
              <Link
                to="/"
                className="inline-flex text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300 font-medium mb-4"
              >
                ← Back to login
              </Link>

              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">
                Forgot your password
              </h2>
              <p className="text-base text-gray-600 dark:text-gray-300">
                Enter your email below and we'll send you a link to reset your
                password.
              </p>
            </div>

            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900 dark:text-gray-100"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleChange}
                    required
                    placeholder="Enter your email"
                    className="w-full bg-white dark:bg-slate-700 text-gray-900 dark:text-white px-4 py-3 rounded-lg border border-gray-300 dark:border-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={!email}
                className="w-full text-white bg-primary-50 py-3 px-4 rounded-lg hover:bg-primary-40 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
