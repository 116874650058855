import React, { lazy, Suspense, useEffect } from 'react';
import { Login, ForgotPassword, RequestRegistrationEmail, Register, ResetPassword } from '@modules/auth';
import { User } from '@types';
import { Sidebar, PinnedCards } from 'components';
import { useQuery } from '@tanstack/react-query';
import { CreoviaLoad } from '@modules/common/components';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { getUser } from 'services/api';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppLayout } from '@modules/common/layouts';
import ErrorFallback from './@modules/errors/ErrorFallback';
import { toast, Toaster } from 'sonner';
import { Tooltip } from 'react-tooltip';
import { SidebarHamburger } from 'components/Topbar/components';
import { TooltipProvider } from 'components/ui/tooltip';

{
    /* Code-Split */
}
const Home = lazy(() => import('@modules/home'));

const DirectoryHome = lazy(() => import('@modules/directory/pages/DirectoryHome'));
const Directory = lazy(() => import('@modules/directory/Directory'));
const DirectoryGroups = lazy(() => import('@modules/directory/pages/DirectoryGroups/DirectoryGroups'));
const DirectoryGroup = lazy(() => import('@modules/directory/pages/DirectoryGroup'));
const DirectoryOrg = lazy(() => import('@modules/directory/pages/DirectoryOrg'));

const Observation = lazy(() => import('@modules/perform/pages/Observation'));
const ObservationsDashboard = lazy(() => import('@modules/perform/pages/ObservationsDashboard'));
const PerformNA = lazy(() => import('@modules/perform/pages/PerformNA/PerformNA'));

const Perform = lazy(() => import('@modules/perform'));
const PerformGroups = lazy(() => import('@modules/perform/pages/PerformGroups'));
const PerformGroup = lazy(() => import('@modules/perform/pages/PerformGroup'));
const PerformOrg = lazy(() => import('@modules/perform/pages/PerformOrg'));
const MyObservations = lazy(() => import('@modules/perform/pages/MyObservations'));

const Magnify = lazy(() => import('@modules/magnify/Magnify'));
const MagnifyHome = lazy(() => import('@modules/magnify/pages/MagnifyHome'));
const MagnifyCollection = lazy(() => import('@modules/magnify/pages/Collection'));

const PayListings = lazy(() => import('@modules/pay/pages/Listings'));
const PayAdmin = lazy(() => import('@modules/pay/pages/Admin'));
const PayProducts = lazy(() => import('@modules/pay/pages/Products'));
const PayProductItem = lazy(() => import('@modules/pay/pages/ProductItem'));

const Enroll = lazy(() => import('@modules/enroll/Enroll'));

const AnnounceDashboard = lazy(() => import('@modules/announce/pages/Dashboard'));
const Announce = lazy(() => import('@modules/announce'));

const Ride = lazy(() => import('@modules/ride'));
const RideGroups = lazy(() => import('@modules/ride/pages/RideGroups'));
const RideGroup = lazy(() => import('@modules/ride/pages/RideGroup'));
const Session = lazy(() => import('@modules/ride/pages/Session'));
const SessionDetail = lazy(() => import('@modules/ride/pages/SessionDetail'));
const Dispatch = lazy(() => import('@modules/ride/pages/Dispatch'));
const Present = lazy(() => import('@modules/ride/pages/Present'));
const PresentReceiver = lazy(() => import('@modules/ride/pages/PresentReceiver'));

const Sign = lazy(() => import('@modules/sign'));
const SignGroups = lazy(() => import('@modules/sign/pages/SignGroups'));
const SignGroup = lazy(() => import('@modules/sign/pages/SignGroup'));
const MyPackets = lazy(() => import('@modules/sign/pages/MyPackets'));
const PacketSigner = lazy(() => import('@modules/sign/pages/PacketSigner'));
const DocumentViewer = lazy(() => import('@modules/sign/pages/DocumentViewer'));
const PacketViewer = lazy(() => import('@modules/sign/pages/PacketViewer'));
const SignatureRequest = lazy(() => import('@modules/sign/pages/SignatureRequest'));
const TemplateDesigner = lazy(() => import('@modules/sign/pages/TemplateDesigner/TemplateDesigner'));

function App() {
    const { data: user, isLoading } = useQuery<User>({
        queryKey: ['user'],
        queryFn: getUser,
        refetchOnWindowFocus: false,
        retry: false,
    });

    const location = useLocation();
    const outlet = location.state?.outlet || '/home';

    {
        /* Authenticating... */
    }
    if (isLoading) return <CreoviaLoad />;

    return (
        <>
            <Toaster
                position="bottom-right"
                toastOptions={{
                    className: 'shadow',
                    classNames: {
                        success: 'text-foreground bg-background',
                        error: 'bg-error-70 text-neutral-5 border-error-60',
                        info: ' dark:bg-primary-50 text-neutral-90',
                    },
                }}
            />
            <TooltipProvider>
                <Tooltip id="tooltip-info" style={{ zIndex: 10 }} />
                <ErrorBoundary fallback={<ErrorFallback />}>
                    {user && !user.force_password_change ? ( // Protected Routes
                        <div className="app-container flex bg-neutral-95 text-neutral-5">
                            <Sidebar />
                            <SidebarHamburger />

                            <div id="middle-column" className="@container middle-column overflow-y-auto h-screen w-full relative">
                                <Suspense
                                    fallback={
                                        <div className="flex w-full flex-col gap-10 mx-auto lg:px-10" style={{ maxWidth: '2560px' }}>
                                            <AppLayout>
                                                <div />
                                            </AppLayout>
                                        </div>
                                    }
                                >
                                    <main className="mx-auto" style={{ maxWidth: '2560px' }}>
                                        <Routes>
                                            <Route path="/" element={<Navigate to="/home" />} />
                                            <Route path="/login" element={<Navigate to={outlet} />} />

                                            <Route path="/home" element={<Home />} />

                                            {/* Our Apps */}
                                            <Route path="/directory" element={<DirectoryHome />} />
                                            <Route path="/directory/groups" element={<DirectoryGroups />} />
                                            <Route path="/directory/:groupId" element={<DirectoryGroup />} />
                                            <Route path="/directory/org/:orgId" element={<DirectoryOrg />} />

                                            {/* TODO UPDATE PERFORM COMPONENT WHEN IT IS READY */}
                                            <Route path="/perform" element={<Perform />} />
                                            <Route path="/perform/groups" element={<PerformGroups />} />
                                            <Route path="/perform/my-observations" element={<MyObservations />} />
                                            <Route path="/perform/groups/:groupId" element={<PerformGroup />} />
                                            <Route path="/perform/organizations/:orgId" element={<PerformOrg />} />

                                            <Route path="/perform/:resource/:groupId?/observation/:type/:observationId" element={<Observation />} />

                                            <Route path="/magnify/dashboard/:titleWithID" element={<Magnify />} />
                                            <Route path="/magnify" element={<Magnify />} />
                                            <Route path="/magnify/:dataset" element={<MagnifyHome />} />
                                            <Route path="/magnify/:dataset/:collection/:collectionId" element={<MagnifyCollection />} />

                                            <Route path="/pay" element={<PayListings />} />
                                            <Route path="/pay/admin" element={<PayAdmin />} />
                                            <Route path="/pay/products" element={<PayProducts />} />

                                            <Route path="/magnify" element={<MagnifyHome />} />
                                            <Route path="/magnify/dashboard/:titleWithID" element={<Magnify />} />

                                            <Route path="/ride" element={<Ride />} />
                                            <Route path="/ride/groups" element={<RideGroups />} />
                                            <Route path="/ride/group/:groupId/session/:sessionId/detail" element={<SessionDetail />} />
                                            <Route path="/ride/group/:id" element={<RideGroup />} />
                                            <Route path="/ride/session/:sessionId" element={<Session />} />
                                            <Route path="/ride/dispatch/:sessionId" element={<Dispatch />} />
                                            <Route path="/ride/present/:sessionId" element={<Present />} />
                                            <Route path="/ride/present/receiver/:sessionId" element={<PresentReceiver />} />

                                            <Route path="/announce" element={<Announce />} />
                                            <Route path="/announce/dashboard" element={<AnnounceDashboard />} />

                                            <Route path="/sign" element={<Sign />} />
                                            <Route path="/sign/groups" element={<SignGroups />} />
                                            <Route path="/sign/group/:id" element={<SignGroup />} />
                                            <Route
                                                path={'/sign/packet/:packetId/group/:groupId/signature-request/:signatureRequestId'}
                                                element={<PacketSigner />}
                                            />
                                            <Route path={'/sign/my-packets'} element={<MyPackets />} />
                                            <Route path={'/sign/design-template'} element={<TemplateDesigner />} />
                                            <Route path={'/sign/document-viewer'} element={<DocumentViewer />} />
                                            <Route
                                                path={'/sign/packet-viewer/:packetId/group/:groupId/signature-request/:signatureRequestId'}
                                                element={<PacketViewer />}
                                            />
                                            <Route path={'/sign/signature-request'} element={<SignatureRequest />} />

                                            {/* Enroll */}

                                            <Route path="/*" element={<ErrorFallback />} />
                                        </Routes>
                                    </main>
                                </Suspense>
                            </div>

                            <PinnedCards />
                        </div>
                    ) : (
                        // Public Routes
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/reset" element={user ? <Navigate to="/" /> : <ForgotPassword />} />{' '}
                            {/* Weird logic, but it makes no assumptions on how things are supposed to work (removes this route on force password change) */}
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/signup" element={<RequestRegistrationEmail />} />
                            <Route path="/confirm-registration" element={<Register />} />
                            <Route path="/*" element={<Navigate to="/login" state={{ outlet: location.pathname }} />} />
                        </Routes>
                    )}
                </ErrorBoundary>
            </TooltipProvider>

            <ReactQueryDevtools initialIsOpen={false} />
        </>
    );
}

export default App;
